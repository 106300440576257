<template>
  <div>
    <fieldset style="width:97%">
      <legend>搜索信息</legend>
      <el-row :gutter="20">
        <el-col :span="3">
          <el-input v-model="search.name" placeholder="客户姓名" size="medium"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="search.orderNo" placeholder="订单号" size="medium"></el-input>
        </el-col>
        <el-col :span="3">
          <el-input v-model="search.phone" placeholder="联系方式" size="medium"></el-input>
        </el-col>
        <el-col :span="4">
          <el-cascader
              :props="{ checkStrictly: true,
                        expandTrigger: 'hover',
                        multiple: true}"
              multiple="false"
              size="medium"
              :show-all-levels="false"
              style="width: 100%;"
              :options="sourceArray"
              v-model="source"
              placeholder="渠道来源"
              ref="source"
              collapse-tags
              @change="cascaderClsoe(source)"
              clearable></el-cascader>
        </el-col>
        <el-col :span="4">
          <el-date-picker
              style="width: 100%"
              v-model="createDateArray"
              type="daterange"
              size="medium"
              range-separator="至"
              start-placeholder="下单日期"
              value-format="yyyy-MM-dd"
              end-placeholder="下单日期">
          </el-date-picker>
        </el-col>
        <el-col :span="4">
          <el-date-picker
              style="width: 100%"
              size="medium"
              v-model="weddingDayArray"
              type="daterange"
              range-separator="至"
              start-placeholder="精确婚期"
              value-format="yyyy-MM-dd"
              end-placeholder="精确婚期">
          </el-date-picker>
        </el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 15px">
        <el-col :span="3">
          <el-select v-model="search.confirmClothes" size="medium" placeholder="是否撞档" clearable>
            <el-option
                v-for="item in confirmArray"
                :key="item.value"
                :label="item.value"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.orderName" size="medium" placeholder="订单项目" clearable @change="orderNameChange(search.orderName)">
            <el-option
                v-for="item in orderNameArray"
                :key="item.value"
                :label="item.name"
                :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.state" placeholder="订单状态" size="medium" clearable @change="stateChange(search.state)">
            <el-option
                v-for="item in stateArray"
                :key="item.value"
                :label="item.name"
                :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.service" placeholder="客服" size="medium" clearable @change="serviceChange(search.service)">
            <el-option
                v-for="item in serviceArray"
                :key="item.value"
                :label="item.name"
                :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.orderDress" size="medium" placeholder="礼服师" clearable @change="orderDressChange(search.orderDress)">
            <el-option
                v-for="item in dressArray"
                :key="item.value"
                :label="item.name"
                :value="item">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.orderState" size="medium" placeholder="收款进度" clearable @change="orderStateChange(search.orderState)">
            <el-option
                v-for="item in orderStateArray"
                :key="item.value"
                :label="item.value"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
        <el-col :span="3">
          <el-select v-model="search.shopIds" size="medium" multiple collapse-tags placeholder="礼服所在店铺" clearable>
            <el-option
                v-for="item in shopArray"
                :key="item.value"
                :label="item.name"
                :value="item.value">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row :gutter="23" style="margin-top: 15px">
        <el-dropdown placement="bottom-start" style="width: 60%">
          <el-col :span="15">
            <el-input readonly="readonly" class="el-dropdown-link" placeholder="常用条件">
              <el-tag v-if='recordObject && recordObject.sourceName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.sourceName }}</el-tag>
              <el-tag v-if='recordObject && recordObject.orderName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.orderName }}</el-tag>
              <el-tag v-if='recordObject && recordObject.stateName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.stateName }}</el-tag>
              <el-tag v-if='recordObject && recordObject.serviceName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.serviceName }}</el-tag>
              <el-tag v-if='recordObject && recordObject.dressName!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.dressName }}</el-tag>
              <el-tag v-if='recordObject && recordObject.orderState!=""' style="margin-top: 1%;margin-left: 2px" slot="prefix" type="success">{{ this.recordObject.orderState }}</el-tag>
            </el-input>
          </el-col>
          <el-col :span="8">
            <el-button el-dropdown-link type="success" icon="el-icon-star-off" size="medium " @click="collectionRecords()" circle></el-button>
            <el-button type="primary" @click="queryOrderList" size="medium">搜索</el-button>
            <el-button type="warning" @click="reset" size="medium">重置</el-button>
          </el-col>
          <el-dropdown-menu  slot="dropdown" style="width: 35%;opacity: 0.95">
            <el-dropdown-item
                v-for="item in recordArray"
                :key="item.value"
                :label="item.showString"
                :value="item"
            >{{ item.preferenceSerialNumber }} : <el-tag type="warning" style="width: 90%" @click="useRecord(item)">{{item.showString}}</el-tag>
              <el-button type="danger" icon="el-icon-delete" size="medium" @click="deleteRecords(item)" circle></el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </el-row>
    </fieldset>
    <el-row style="margin-top: 15px;padding-left: 10px">
      <el-table
          :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
          :data="orderArray"
          :key="tableKey"
          ref="table"
          border
          v-loading="loading"
          element-loading-text="拼命加载中"
          max-height="635"
          style="width: 99%">
        <el-table-column prop="name" label="客户名" align="center" width="100" fixed
                         :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="orderNo" label="订单号" align="center" width="120"
                         :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="orderName" label="订单项目" align="center" width="120"
                         :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="phone" label="联系方式" align="center" width="120"
                         :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="createDate" label="下单日期" align="center" width="120" sortable></el-table-column>
        <el-table-column prop="service" label="客服" align="center" width="120"></el-table-column>
        <el-table-column prop="dress" label="礼服师" align="center" width="120"></el-table-column>
        <el-table-column prop="source" label="渠道来源" align="center" width="120"
                         :show-overflow-tooltip="true"></el-table-column>
        <el-table-column prop="weddingDay" label="精确婚期" align="center" width="120"
                         show-overflow-tooltip></el-table-column>
        <el-table-column prop="orderPrice" label="订单总价" align="center" width="120"></el-table-column>
        <el-table-column prop="spareMoney" label="收款金额" align="center" width="120"></el-table-column>
        <el-table-column prop="orderSpare" label="余款" align="center" width="120"></el-table-column>
        <el-table-column prop="state" label="订单状态" align="center" width="120"></el-table-column>
        <el-table-column label="订单进度" align="center" width="120">
          <template slot-scope="scope">
            <span
                :style="{color: scope.row.orderState==='已收满' ? '#00cf22': scope.row.orderState==='已退款' ? '#CF1900' : ''}">
              {{ scope.row.orderState }}
            </span>
          </template>
        </el-table-column>
        <el-table-column prop="collectionProgress" label="收款进度" align="center" width="120"></el-table-column>
        <el-table-column prop="auxiliary" label="辅销" align="center" width="120"></el-table-column>
        <el-table-column prop="cosmetics" label="化妆师" align="center" width="120"></el-table-column>
        <el-table-column prop="orderRemark" label="备注" align="center" width="120"
                         :show-overflow-tooltip="true"></el-table-column>
        <el-table-column align="left" width="680" fixed="right" label="操作" v-if="btnFlag">
          <template slot="header" slot-scope="scope">
            <div @click="btnShow()" style="cursor: pointer;display: flex;align-items: center;justify-content: center">
              <i class="el-icon-caret-right" style="font-size: 20px;color: red"/>
              <span>操作</span>

            </div>

          </template>
          <template slot-scope="scope">
            <el-button-group>
              <per-button type="primary" size="mini" @click="orderAddCloseOpen(scope.row)" per="appoint:order_add">
                订单/升级
              </per-button>
              <per-button v-show="scope.row.orderName!=='退单'" type="primary" size="mini"
                          @click="appAddOpen(scope.row)" per="order:after_sale_app_add">售后预约
              </per-button>
              <per-button v-show="scope.row.orderName!=='退单'" type="primary" size="mini"
                          @click="addProceedsOpen(scope.row)" per="order:add_proceed">追加收款
              </per-button>
              <per-button v-show="scope.row.orderName!=='退单'" type="success" size="mini"
                          @click="orderUpdateOpen(scope.row)" per="order:edit">编辑
              </per-button>
              <per-button v-show="scope.row.orderName!=='退单'" type="primary" size="mini"
                          @click="orderAddScheduleOpen(scope.row)" per="order:add_schedule">添加婚纱
              </per-button>
              <per-button v-show="scope.row.orderName!=='退单'" type="danger" size="mini"
                          @click="refundAddOpen(scope.row)" per="order:refund">退单
              </per-button>
              <per-button v-show="scope.row.orderName!=='退单'" type="warning" size="mini"
                          @click="orderImageOpen(scope.row)" per="order:order_image">订单图片
              </per-button>
              <per-button type="danger" size="mini" @click="deleteOrder(scope.row)" per="order:delete">删除</per-button>
              <per-button v-if="scope.row.orderName!=='退单'" type="danger" size="mini" @click="refundDepositOpen(scope.row)" per="order:refund_deposit">退押金</per-button>
            </el-button-group>
          </template>
        </el-table-column>
        <el-table-column align="center" width="150" label="操作" v-if="!btnFlag">
          <template slot="header" slot-scope="scope">
            <div @click="btnShow()" style="cursor: pointer;display: flex;align-items: center;justify-content: center">
              <i class="el-icon-caret-left" style="font-size: 20px;color: blue"/>
              <span>操作</span>
            </div>
          </template>
          <template slot-scope="scope">
            <el-button-group>
              <per-button type="primary" size="mini" @click="orderAddCloseOpen(scope.row)" per="appoint:order_add">
                订单/升级
              </per-button>
            </el-button-group>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row style="margin-top: 3px">
      <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="100"
          layout="prev, pager, next,total, jumper, sizes"
          :total="total">
      </el-pagination>
    </el-row>
    <orderAdd :app="app" :orderAddState="orderAddState" v-if="orderAddState" @close="orderAddClose"></orderAdd>
    <appAdd v-if="appAddState" :cus="cus" :appAddState="appAddState" @close="appAddClose"></appAdd>
    <orderImages :orderImageState="orderImageState" v-if="orderImageState" @close="orderImageClose"></orderImages>
    <orderAddSchedule :orderAddScheduleState="orderAddScheduleState" v-if="orderAddScheduleState"
                      @close="orderAddScheduleClose"></orderAddSchedule>
    <addToProceeds :addProceedsState="addProceedsState" v-if="addProceedsState"
                   @close="addProceedsClose"></addToProceeds>
    <refundAdd :refundAddState="refundAddState" v-if="refundAddState" @close="refundAddClose"></refundAdd>
    <orderUpdate :orderUpdateState="orderUpdateState" v-if="orderUpdateState" @close="orderUpdateClose"></orderUpdate>
    <recordSelect v-if="recordSelect" :recordObject="recordObject" :recordArray="recordArray"
                  :recordState="recordSelect" @close="recordSelectAddClose"/>
    <recordSelect v-if="recordSelect" :recordObject="recordObject" :recordArray="recordArray" :recordState="recordSelect" @close="recordSelectAddClose"/>
    <returnDeposit :refundDepositData="refundDepositData" v-if="refundDepositState" @close="refundDepositClose"></returnDeposit>
  </div>
</template>

<script>
import orderImages from "@/pages/order/order-images"
import orderUpdate from "@/pages/order/order-update"
import addToProceeds from "@/pages/proceeds/add-to-proceeds"
import orderAddSchedule from "@/pages/clothes/order-add-schedule"
import refundAdd from "@/pages/refund/refund-add"
import appAdd from "@/pages/appoint/app-add"
import recordSelect from "@/components/record/record-select"
import orderAdd from "@/pages/order/order-add"
import returnDeposit from "./refund-deposit.vue"

export default {
  name: "order-list",
  components: {
    orderImages,
    addToProceeds,
    orderAddSchedule,
    refundAdd,
    orderUpdate,
    appAdd,
    orderAdd,
    recordSelect,
    returnDeposit
  },
  created() {
    this.pageInit()
  },
  provide() {
    return {
      order: this.order
    }
  },
  data() {
    return {
      btnFlag: false,
      tableKey: 1,
      search: {
        name: "",
        orderNo: "",
        phone: "",
        confirmClothes: "",
        orderState: "",
        createDate: "",
        orderName: "",
        weddingDaySection: "",
        orderDress: "",
        state: "",
        service: "",
        shopIds: [],
      },
      confirmArray: [
        {value: "撞档"},
        {value: "已确认"},
        {value: "档期未定"},
      ],
      orderStateArray: [
        {value: "已收满"},
        {value: "未收满"},
        {value: "已退款"},
      ],
      testMessage: "",
      sourceResult: "",
      source: "",
      orderNameArray: [],
      dressArray: [],
      createDateArray: [],
      weddingDayArray: [],
      orderArray: [],
      sourceArray: [],
      stateArray: [],
      serviceArray: [],
      recordArray: [],
      total: 0,
      page: 1,
      limit: 100,
      tenantCrop: localStorage.getItem("tenantCrop"),
      order: {},
      orderImageState: false,
      addProceedsState: false,
      orderAddScheduleState: false,
      refundAddState: false,
      orderUpdateState: false,
      refundDepositState: false,
      refundDepositData: {},
      loading: true,
      appAddState: false,
      orderAddState: false,
      recordSelect: false,
      cus: {},
      app: {},
      recordObject: {
        orderName: "",
        orderNameId: "",
        sourceName: "",
        sourceId: "",
        stateName: "",
        stateId: "",
        dressName: "",
        dressId: "",
        serviceName: "",
        serviceId: "",
        orderState: "",
        showString: "",
        empId: JSON.parse(localStorage.getItem("emp")).id,
        empName: JSON.parse(localStorage.getItem("emp")).empName,
        tenantCrop: localStorage.getItem("tenantCrop"),
        recordType: "订单列表",
        preferenceSerialNumber: "",
      },
      shopArray: [],
      returnDepositShow: false,
    }
  },
  methods: {
    btnShow() {
      this.btnFlag = !this.btnFlag
      this.tableKey += 1
    },
    pageInit() {
      this.$selectUtils.querySourceIds(this.$selectUtils.cascader).then(response => {
        this.sourceArray = JSON.parse(JSON.parse(response.data.data))
      })
      this.$selectUtils.queryStateIds().then(response => {
        this.stateArray = JSON.parse(response.data.data)
      })
      this.$selectUtils.queryServiceIds().then(response => {
        this.serviceArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryProjectsIds(this.$projectsType.order).then(response => {
        this.orderNameArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryDressIds().then(response => {
        this.dressArray = JSON.parse(response.data.data);
      })
      this.$selectUtils.queryRecord("订单列表").then(response => {
        this.recordArray = response.data.data
        this.recordObject.preferenceSerialNumber = this.recordArray.length + 1
      })
      this.$selectUtils.shopIdsIsValid().then(response => {
        let shops = localStorage.getItem("shops").split(",").map(Number)
        this.shopArray = JSON.parse(response.data.data).filter(s => shops.includes(s.value));
      })
      this.queryOrderList()
    },
    queryOrderList() {
      this.search.createDate = ""
      this.search.weddingDaySection = ""
      if (this.createDateArray != null && this.createDateArray.length > 0) {
        this.search.createDate = this.createDateArray[0] + " - " + this.createDateArray[1]
      }
      if (this.weddingDayArray != null && this.weddingDayArray.length > 0) {
        this.search.weddingDaySection = this.weddingDayArray[0] + " - " + this.weddingDayArray[1]
      }
      this.$axios({
        method: "GET",
        url: "/order/orderList",
        params: {
          name: this.search.name,
          orderNo: this.search.orderNo,
          phone: this.search.phone,
          confirmClothes: this.search.confirmClothes,
          createDateSection: this.search.createDate,
          orderName: this.search.orderName.value,
          weddingDaySection: this.search.weddingDaySection,
          orderDress: this.search.orderDress.value,
          state: this.search.state.value,
          service: this.search.service.value,
          source: this.sourceResult,
          orderState: this.search.orderState,
          tenantCrop: this.tenantCrop,
          limit: this.limit,
          page: this.page,
          searchShopIds: this.search.shopIds.toString(),
          shopIds: localStorage.getItem("shops"),
        }
      }).then(response => {
        this.loading = false
        this.orderArray = response.data.data.list
        this.total = response.data.data.total
      })
    },
    // 查询预约列表 通过收藏记录查询
    useRecord(val) {
      console.log(val)
      this.$axios({
        method: "get",
        url: "/order/orderList",
        params: {
          tenantCrop: localStorage.getItem("tenantCrop"),
          limit: this.limit,
          page: this.page,
          shopIds: localStorage.getItem("shops"),
          source: val.sourceId,
          orderName: val.orderNameId,
          state: val.stateId,
          service: val.serviceId,
          orderDress: val.dressId,
          orderState: val.orderState,
        }
      }).then(response => {
        console.log(response)
        this.loading = false
        this.orderArray = response.data.data.list;
        this.total = response.data.data.total;
      })
    },
    //将记录搜藏存储
    collectionRecords() {
      if (this.recordObject.preferenceSerialNumber > 5) {
        this.recordSelect = true
      } else {
        this.$axios({
          method: "post",
          url: "/record/addRecordOfCustomer",
          params: this.recordObject
        }).then(response => {
          let flag = response.data.code === 200
          this.$message({
            showClose: true,
            message: flag ? '收藏成功' : response.data.msg,
            type: flag ? 'success' : 'error',
            duration: 1000,
          });
          this.reset()
          this.pageInit()
          this.close()
        })
      }
    },
    deleteRecords(val) {
      this.$axios({
        method: "post",
        url: "/record/deleteById",
        params: {
          id: val.id,
        }
      }).then(response => {
        let flag = response.data.code === 200
        this.$message({
          showClose: true,
          message: flag ? '删除成功' : response.data.msg,
          type: flag ? 'success' : 'error',
          duration: 1000,
        });
        this.reset()
        this.pageInit()
        this.close()
      })
    },
    reset() {
      Object.keys(this.search).forEach(key => {
        this.search[key] = ''
      })
      this.recordObject.orderName = ""
      this.recordObject.orderNameId = ""
      this.recordObject.sourceName = ""
      this.recordObject.sourceId = ""
      this.recordObject.stateName = ""
      this.recordObject.stateId = ""
      this.recordObject.dressName = ""
      this.recordObject.dressId = ""
      this.recordObject.serviceName = ""
      this.recordObject.serviceId = ""
      this.recordObject.orderState = ""
      this.recordObject.showString = ""
      this.source = ""
      this.createDateArray = []
      this.weddingDayArray = []
      this.sourceResult = ""
    },
    handleSizeChange(val) {
      this.limit = val
      this.queryOrderList();
    },
    handleCurrentChange(val) {
      this.page = val
      this.queryOrderList()
    },
    orderImageOpen(data) {
      this.orderImageState = true
      Object.assign(this.order, data)
    },
    orderImageClose() {
      this.orderImageState = false
      this.queryOrderList()
    },
    addProceedsOpen(data) {
      this.addProceedsState = true
      Object.assign(this.order, data)
    },
    addProceedsClose() {
      this.addProceedsState = false
      this.queryOrderList()
    },
    orderAddScheduleOpen(data) {
      this.orderAddScheduleState = true
      Object.assign(this.order, data)
    },
    orderAddScheduleClose() {
      this.orderAddScheduleState = false
      this.queryOrderList()
    },
    // 退押金查询订单列表
    refundDepositOpen(row) {
      this.refundDepositState = true
      this.refundDepositData = row
    },
    refundDepositClose() {
      this.refundDepositState = false
      this.refundDepositData = {}
      this.queryOrderList()
    },
    refundAddOpen(data) {
      this.refundAddState = true
      Object.assign(this.order, data)
    },
    refundAddClose() {
      this.refundAddState = false
      this.queryOrderList()
    },
    orderUpdateOpen(data) {
      this.orderUpdateState = true
      Object.assign(this.order, data)
    },
    orderUpdateClose() {
      this.orderUpdateState = false
      this.queryOrderList()
    },
    orderAddCloseOpen(data) {
      console.log(data)
      this.app = {
        appointDressId: data.orderDress,
        appointCosmeticsId: data.orderCosmetics,
        appointCity: data.orderCity,
        cusId: data.cusId,
        id: data.appId,
        shopId: data.shopId,
      }
      this.orderAddState = true
    },
    //级联选择关闭
    cascaderClsoe(val) {
      if ("" != val && null != val) {
        if ("" != this.source) {
          let test = this.source[0]
          Array.from(test)
          if (test.length > 1) {
            this.sourceResult = test[1]
          } else {
            this.sourceResult = test[0]
          }
        }
        this.recordObject.sourceName = this.$refs["source"].getCheckedNodes()[0].label
        this.recordObject.sourceId = this.sourceResult
      } else {
        this.recordObject.sourceName = ""
        this.recordObject.sourceId = ""
        this.sourceResult = ""
      }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
      console.log(val)
    },
    orderNameChange(val) {
      if (null != val && "" != val) {
        this.recordObject.orderName = val.name
        this.recordObject.orderNameId = val.value
      } else {
        this.recordObject.orderName = ""
        this.recordObject.orderNameId = ""
      }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
    },
    stateChange(val) {
      if (null != val && "" != val) {
        this.recordObject.stateName = val.name
        this.recordObject.stateId = val.value
      } else {
        this.recordObject.stateName = ""
        this.recordObject.stateId = ""
      }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
    },
    serviceChange(val) {
      if (null != val && "" != val) {
        this.recordObject.serviceName = val.name
        this.recordObject.serviceId = val.value
      } else {
        this.recordObject.serviceName = ""
        this.recordObject.serviceId = ""
      }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
    },
    orderDressChange(val) {
      if (null != val && "" != val) {
        this.recordObject.dressName = val.name
        this.recordObject.dressId = val.value
      } else {
        this.recordObject.dressName = ""
        this.recordObject.dressId = ""
      }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
    },
    orderStateChange(val) {
      if (null != val && "" != val) {
        this.recordObject.orderState = val
      } else {
        this.recordObject.orderState = ""
      }
      this.recordObject.showString = this.showString()
      this.testMessage = this.recordObject.showString
    },
    showString() {
      return this.recordObject.sourceName + ";" + this.recordObject.orderName + ";"
          + this.recordObject.stateName + ";" + this.recordObject.serviceName + ";"
          + this.recordObject.dressName + ";" + this.recordObject.orderState
    },
    recordSelectAddClose() {
      this.pageInit()
      this.recordSelect = false
    },
    orderAddClose() {
      this.orderAddState = false
      this.queryOrderList()
    },
    //删除订单
    deleteOrder(data) {
      this.$alert("删除订单时将会同时删除该订单相关的 收款、退款、档期"
          , "确认要删除客户 " + data.name + " 的这条订单吗？",
          {
            dangerouslyUseHTMLString: true,
            type: "warning",
            showCancelButton: true
          }).then(() => {
        this.$axios({
          method: "delete",
          url: "/order/deleteOrderById",
          params: {
            id: data.id,
          }
        }).then(response => {
          let flag = response.data.code === 200
          this.$message({
            showClose: true,
            message: flag ? '订单删除成功' : response.data.msg,
            type: flag ? 'success' : 'error',
            duration: 1000,
          })
          this.queryOrderList();
        })
      }).catch(() => {
      })
    },
    appAddClose() {
      this.appAddState = false
      this.queryOrderList()
    },
    close() {
      this.$emit('close')
    },
    appAddOpen(val) {
      this.appAddState = true
      this.cus.name = val.name
      this.cus.phone = val.phone
      this.cus.weChat = val.weChat
      this.cus.id = val.cusId
      this.cus.tenantCrop = val.tenantCrop
      this.cus.stateId = val.stateId
    }
  },
  activated() {
    this.$nextTick(() => {
      this.$refs.table.doLayout(); //解决表格错位
    });
  }
}
</script>

<style scoped>

</style>
