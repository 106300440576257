<template>
  <div>
    <el-dialog
        title="退押金"
        width="80%"
        top="10vh"
        :visible="true"
        :modal-append-to-body="false"
        @close="close"
        center>
      <el-divider style="font-size: 28px;" content-position="left">
        客户 {{ refundDepositData.name }} 的所有订单
      </el-divider>
      <el-row style="margin-top: 15px;padding-left: 10px">
        <el-table
            :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
            :data="orderList"
            ref="table"
            border
            v-loading="loading"
            element-loading-text="拼命加载中"
            max-height="635"
            style="width: 99%">
          <el-table-column prop="name" label="客户名" align="center" width="100" fixed :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="shopName" label="订单店铺" align="center" width="120"></el-table-column>
          <el-table-column prop="service" label="客服" align="center" width="120"></el-table-column>
          <el-table-column prop="orderNo" label="订单号" align="center" width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="createDate" label="下单日期" align="center" width="120" sortable></el-table-column>
          <el-table-column prop="weddingDay" label="精确婚期" align="center" width="120" show-overflow-tooltip></el-table-column>
          <el-table-column prop="orderName" label="订单项目" align="center" width="120" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column prop="orderPrice" label="订单总价" align="center" width="120"></el-table-column>
          <el-table-column prop="spareMoney" label="收款金额" align="center" width="120"></el-table-column>
          <el-table-column prop="orderSpare" label="余款" align="center" width="120"></el-table-column>
          <el-table-column prop="dress" label="礼服师" align="center" width="120"></el-table-column>
          <el-table-column prop="auxiliary" label="辅助礼服师" align="center" width="120"></el-table-column>
<!--          <el-table-column prop="phone" label="联系方式" align="center" width="120" :show-overflow-tooltip="true"></el-table-column>-->
<!--          <el-table-column prop="source" label="渠道来源" align="center" width="120" :show-overflow-tooltip="true"></el-table-column>-->
<!--          <el-table-column prop="state" label="订单状态" align="center" width="120"></el-table-column>-->
          <!--          <el-table-column label="订单进度" align="center" width="120">-->
          <!--            <template slot-scope="scope">-->
          <!--            <span :style="{color: scope.row.orderState==='已收满' ? '#00cf22': scope.row.orderState==='已退款' ? '#CF1900' : ''}">-->
          <!--              {{scope.row.orderState}}-->
          <!--            </span>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
        </el-table>
      </el-row>

      <el-divider style="font-size: 28px;" content-position="left">
        客户 {{ refundDepositData.name }} 的所有的收款
      </el-divider>
      <el-row style="margin-top: 15px;padding-left: 10px">
        <el-table
            :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
            :data="proceedsArray"
            ref="table"
            border
            v-loading="loading"
            element-loading-text="拼命加载中"
            max-height="635"
            style="width: 99%">
          <el-table-column prop="name" label="客户名" align="center"></el-table-column>
          <el-table-column prop="shopName" label="订单店铺" align="center"></el-table-column>
          <el-table-column prop="orderNo" label="订单号" align="center"></el-table-column>
          <el-table-column prop="createDate" label="收款日期" sortable align="center" width="120"></el-table-column>
          <el-table-column prop="proceedsName" label="收款项目" align="center"></el-table-column>
          <el-table-column prop="spareMoney" label="收款金额" align="center"></el-table-column>
          <el-table-column prop="payment" label="收款方式" align="center"></el-table-column>
          <el-table-column prop="payee" label="收款人" align="center"></el-table-column>
          <el-table-column prop="refundRemark" label="押金/意向金状态" align="center" width="135"></el-table-column>
          <el-table-column prop="weddingDay" label="订单婚期" align="center" width="120"></el-table-column>
          <el-table-column label="操作" width="88" fixed="right">
            <template slot-scope="scope">
              <el-button-group>
                <el-button type="danger" size="mini" @click="refundEvent(scope.row)"
                           style="line-height: 8px; padding: 4px 8px;">退押金</el-button>

<!--              <el-button type="danger" size="mini" @click="refundEvent(scope.row)"-->
<!--                         v-if="scope.row.proceedsName === '押金' && scope.row.refundRemark != '押金已退'"-->
<!--                         style="line-height: 8px; padding: 4px 8px;">退押金</el-button>-->
              </el-button-group>

            </template>
          </el-table-column>
        </el-table>
      </el-row>

      <el-divider style="font-size: 28px;" content-position="left">
        客户 {{ refundDepositData.name }} 的所有退款
      </el-divider>
      <el-row style="margin-top: 20px;padding-left: 10px">
        <el-table
            :header-cell-style="{background:'#fafafc',color:'#606266',padding: '3px 0'}"
            :data="refundList"
            :row-style="{height:30+'px'}"
            :cell-style="{padding:5+'px'}"
            border
            v-loading="loading"
            element-loading-text="拼命加载中"
            max-height="635"
            :default-sort = "{prop: 'createDate', order: 'descending'}"
            style="width: 99%">
          <el-table-column prop="cusName" label="客户名" align="center" width="100" fixed></el-table-column>
          <el-table-column prop="refundProjectsName" label="退单项目" align="center"  width="100" fixed></el-table-column>
          <el-table-column prop="sourceName" label="联系渠道" align="center"  width="180" fixed></el-table-column>
          <el-table-column prop="refundAmount" align="center" label="退款金额" width="100"></el-table-column>
          <el-table-column prop="createDate" align="center" label="退款时间" width="170" ></el-table-column>
          <el-table-column prop="refundPeopleName" align="center" label="退款人" width="100"></el-table-column>
          <el-table-column prop="refundPayment" align="center" label="退款方式" width="150"></el-table-column>
          <el-table-column prop="refundTarget" align="center" label="退款目标账户" width="120"></el-table-column>
          <el-table-column prop="targetAccount" align="center" label="退款目标账号" width="150"></el-table-column>
        </el-table>
      </el-row>


      <div v-if="showRefundForm">
        <el-steps :active="activeIndex">
          <el-step title="填写退押金信息"/>
          <el-step title="添加退押金图片"/>
          <el-step title="确认退押金信息"/>
          <el-step title="完成"/>
        </el-steps>

        <div>
          <div v-if="activeIndex === 0">
            <el-form :inline="true" label-width="120px"	:rules="rules" ref="form1" :model="orderForm" class="demo-form-inline" style="width: 80%;margin: 0 auto">
              <el-form-item label="客户名">
                <el-input v-model="orderForm.userName"  :disabled="true" clearable></el-input>
              </el-form-item>
              <el-form-item label="手机号码">
                <el-input v-model="orderForm.phone"  :disabled="true"  clearable></el-input>
              </el-form-item>
              <el-form-item label="订单总和" >
                <el-input v-model="orderForm.orderSumAmount"  :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="收款总和" >
                <el-input v-model="orderForm.receiveSumAmount"  :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="退款时间" prop="createDate">
                <el-date-picker type="datetime" placeholder="退款时间"
                                v-model="orderForm.createDate"
                                value-format="yyyy-MM-dd HH:mm:ss">
                </el-date-picker>
              </el-form-item>
                <el-form-item label="退款人" prop="refundPeople" >
                  <el-input v-model="orderForm.refundPeople" placeholder="请填写退款人" ></el-input>
                </el-form-item>
                <el-form-item label="退款方式：" prop="refundMethodId">
                  <el-select v-model="orderForm.refundMethodId" placeholder="请选择退款方式" clearable
                             ref="refundMethodId">
                    <el-option
                        v-for="item in refundMethodArray"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="押金金额(负数)" prop="refundAmount">
                  <el-input v-model="orderForm.refundAmount" placeholder="请填写押金金额(负数"></el-input>
                </el-form-item>
                <el-form-item label="退款目标账户" prop="refundTarget">
                  <el-input v-model="orderForm.refundTarget" placeholder="请填写退款目标账户"></el-input>
                </el-form-item>
                <el-form-item label="退款目标账号" prop="targetAccount">
                  <el-input v-model="orderForm.targetAccount"  placeholder="请填写退款目标账号"></el-input>
                </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="orderNext">下一步</el-button>
              </el-form-item>
            </el-form>
          </div>

          <div v-else-if="activeIndex === 1">
              <div style="display: flex; flex-direction: row; justify-content: center; padding-top: 20px;">
                <UploadImages :imgSize="1" @files="getFiles"/>
                <el-button @click="previous">上一步</el-button>
                <el-button type="primary" @click="next">下一步</el-button>
              </div>
          </div>

          <div v-if="activeIndex === 2">
            <el-form :inline="true" label-width="120px"	:rules="rules" ref="form2" :model="orderForm" class="demo-form-inline" style="width: 80%;margin: 0 auto">
              <el-form-item label="客户名">
                <el-input v-model="orderForm.userName"  :disabled="true" clearable></el-input>
              </el-form-item>
              <el-form-item label="手机号码">
                <el-input v-model="orderForm.phone"  :disabled="true"  clearable></el-input>
              </el-form-item>
              <el-form-item label="订单总和" >
                <el-input v-model="orderForm.orderSumAmount"  :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="收款总和" >
                <el-input v-model="orderForm.receiveSumAmount"  :disabled="true"></el-input>
              </el-form-item>
              <el-form-item label="退款时间" prop="createDate">
                <el-date-picker type="date" placeholder="退款时间"
                                v-model="orderForm.createDate"
                                value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
              <el-form-item label="退款人" prop="refundPeople" >
                <el-input v-model="orderForm.refundPeople" placeholder="请填写退款人" ></el-input>
              </el-form-item>
              <el-form-item label="退款方式：" prop="refundMethodId">
                <el-select v-model="orderForm.refundMethodId" placeholder="请选择退款方式" clearable
                           ref="refundMethodId">
                  <el-option
                      v-for="item in refundMethodArray"
                      :key="item.value"
                      :label="item.name"
                      :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="押金金额(负数)" prop="refundAmount">
                <el-input v-model="orderForm.refundAmount" placeholder="请填写押金金额(负数"></el-input>
              </el-form-item>
              <el-form-item label="退款目标账户" prop="refundTarget">
                <el-input v-model="orderForm.refundTarget" placeholder="请填写退款目标账户"></el-input>
              </el-form-item>
              <el-form-item label="退款目标账号" prop="targetAccount">
                <el-input v-model="orderForm.targetAccount"  placeholder="请填写退款目标账号"></el-input>
              </el-form-item>

              <el-form-item>
                <el-button @click="previous">上一步</el-button>
                <el-button @click="returnDepositMoney" type="danger" >退押金</el-button>
              </el-form-item>
            </el-form>
          </div>


        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import UploadImages from "@/components/image/uploadImages.vue"
import mathUtils from "@/common/utils/math-utils"

export default {
  name: "return-deposit",
  inject: ['order'],
  created() {
    this.pageInit()
  },
  components:{
    UploadImages
  },
  props: {
    refundDepositData: {
      type: Object,
      default: ()=>{},
    }
  },
  data() {
    return {
      fileList: [],
      dialogImageUrl: '',
      orderList:[],
      refundList:[],
      proceedsArray:[],
      refundMethodArray:[],
      loading:false,
      showRefundForm: false,
      activeIndex: 0,
      refundDepositDataRow: {},
      orderForm: {
        userName : this.refundDepositData.name,
        orderSumAmount:0,
        receiveSumAmount:0,
        phone:"",
        refundPeople:"",
        createDate:"",
        refundMethodId:"",
        refundAmount:"",
        refundTarget:"",
        targetAccount:"",
        commImage: "",
      },
      rules: {
        createDate: [{required: true, message: '请选择退款日期', trigger: 'blur'}],
        refundPeople: [{required: true, message: '请选择退款人', trigger: 'blur'}],
        refundAmount: [{required: true, message: '请填写退款金额', trigger: 'change'}],
        refundMethodId: [{required: true, message: '请选择退款方式', trigger: 'blur'}],
        refundTarget: [{required: true, message: '请选择退款目标账户', trigger: 'blur'}],
        targetAccount: [{required: true, message: '请选择退款目标账号', trigger: 'blur'}],
      },
    }
  },
  methods: {
    pageInit() {
      console.log(this.refundDepositData.cusId);
      this.queryOrderList()
      this.queryRefundList()
      this.queryProceedsList()
    },
    close() {
      this.$emit("close");
    },

    //根据客资id查询收款列表
    queryProceedsList() {
      this.$axios({
        method: "GET",
        url: "/proceeds/proceedsList",
        params: {
          cusId: this.refundDepositData.cusId,
          tenantCrop:this.refundDepositData.tenantCrop,
          limit: 10,
          page: this.page,
        }
      }).then(response => {
        this.proceedsArray = response.data.data.list
        this.total = response.data.data.total
      })
    },

    //根据客资id查询订单列表
    queryOrderList() {
      this.$axios({
        method: "GET",
        url: "/order/orderList",
        params: {
          cusId: this.refundDepositData.cusId,
          tenantCrop:this.refundDepositData.tenantCrop,
          limit: 10,
          page: this.page,
        }
      }).then(response => {
        this.orderList=response.data.data.list;
        this.orderList.forEach(v=>{
          this.orderForm.orderSumAmount = mathUtils.add(this.orderForm.orderSumAmount,Number(v.orderPrice));
          this.orderForm.receiveSumAmount = mathUtils.add(this.orderForm.receiveSumAmount,Number(v.spareMoney));
          this.orderForm.phone = v.phone;
        })
        console.log(this.orderForm.orderSumAmount)
        console.log(this.orderList)
      })
    },

    //根据客资id查询退款列表
    queryRefundList() {
      this.$axios({
        method: "GET",
        url: "/refund/queryList",
        params: {
          cusId: this.refundDepositData.cusId,
          tenantCrop:this.refundDepositData.tenantCrop,
          limit: 100,
          page: this.page,
        }
      }).then(response => {
        this.refundList=response.data.data.list;
        console.log(this.orderList)
      })
    },

    refundEvent(row) {
      this.$selectUtils.queryRefund().then(response => {
        this.refundMethodArray = response.data.data;
      })
      this.showRefundForm = !this.showRefundForm
      this.refundDepositDataRow  = row;
    },

    orderNext() {
      // this.$refs['form1'].validate((valid) => {
      //   if (valid) {
      //     if (!(this.orderForm.refundAmount < 0)) {
      //       this.$message.error('退款金额应该为负数数字！');
      //       return false
      //     }
      //
      //     if (this.orderForm.refundAmount*-1 > this.refundDepositDataRow.spareMoney){
      //       this.$message.error('押金没收那么多钱!');
      //       return false
      //     }
      //     this.activeIndex++
      //   }
      // })
      // this.activeIndex++
    },

    next() {
      if (this.activeIndex == 1){
        if (this.fileList.length <1){
          this.$alert('请选择图片!', '', {
            confirmButtonText: '确定',
          });
          return false
        }
      }
      this.activeIndex++
    },

    previous() {
      this.activeIndex--
      if (this.activeIndex == 1){
        this.fileList = [];
      }
    },

    //从子组件获取file
    getFiles(file) {

      this.fileList = file;
      console.log(this.fileList)

      console.log(this.fileList[0].name.split("."))
    },
    returnDepositMoney(fileName) {

      this.$refs['form2'].validate((valid) => {
        if (valid) {

          this.$alert('确认要进行押金退款申请吗？', "退押金", {
            dangerouslyUseHTMLString: true,
            type: "warning",
            showCancelButton: true
          }).then(() => {
            this.uploadStyleImage();

          })
        }
      })
    },

    save(fileName){
      this.orderForm.commImage=fileName
      this.$axios({
        method: "post",
        url: `/refund/orderRefundOperate`,
        data: {
          ...this.orderForm,
          id:this.refundDepositData.id,
          dressId:this.refundDepositData.dressId,
          cusId:this.refundDepositData.cusId,
          orderId:this.refundDepositData.id,
          refundPeopleId:JSON.parse(localStorage.getItem("emp")).id,
          tenantCrop: localStorage.getItem("tenantCrop")
        },
      }).then(response => {
        if (response.data.code === 200) {
          this.$message.success("提交成功")
        } else {
          this.$message.error(response.data.msg)
        }
      })
    },
    uploadStyleImage: function () {
      let suffix="";
      if (this.fileList.length>0){
        let suffixArray=this.fileList[0].name.split(".");
        suffix=suffixArray[suffixArray.length-1];
      }

      let _this=this;
      let fileName=`${this.$md5(this.fileList[0].name+this.fileList[0].uid)}.${suffix}`;
      this.$upload.getTemporaryKey(this.$upload.expendImage).putObject({
        Bucket: this.$upload.getBucketName(this.$upload.expendImage), /* 必须 */
        Region: this.$upload.getRegion(),     /* 存储桶所在地域，必须字段 */
        Key: fileName,              /* 必须 */
        StorageClass: 'STANDARD',
        Body: this.fileList[0].raw, // 上传文件对象
      },function(err, data) {
        if (err!==null){
          _this.$message.error("图片上传失败,请刷新重试!")
          return;
        }
        _this.save(fileName)
      });
    },

  }
}
</script>

<style scoped>
/deep/ .el-card__body {
  text-align: center;
}
/deep/ .el-icon-plus{
  margin-left: 5px;
}
/deep/ .el-icon-close{
  margin-left: 5px;
}
/deep/ .el-icon-close:hover {
  color: red;
}
</style>